@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible;
}

code {
  font-family: "DM Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

html,
body,
#root {
  font-size: 16px;
  height: 100%;
}
::-webkit-scrollbar-track {
  border-radius: 0.625em;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0.1875em;
  height: 0.3em;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 3.125em;
  background-color: #818181;
}
/* ::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

::-webkit-scrollbar-track {
  box-shadow: #818181;
  -webkit-box-shadow: #818181; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */

.spinner {
    width: 80px;
    height: 80px;
    background-color: #eb1e45;
    -webkit-animation: square-1 1s infinite ease-in-out;
    animation: square-1 1s infinite ease-in-out;
    /* margin-right: auto;
    margin-left: auto;
    margin-top: 200px; */
  }


.detection-loader {
    border: 3px solid transparent;
    border-radius: 50%;
    border-top: 3px solid #EC1C45;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

  @-webkit-keyframes square-1 {

    0% {
        transform: perspective(160px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
        -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
        -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    }
}

  @keyframes square-1 {

    0% {
        transform: perspective(160px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
        -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
        -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    }
}

@-webkit-keyframes instructorAnimation {
  0% {opacity: 0.2; left: -3em}
  100% {opacity: 1; left: 0.5em}
}

@keyframes instructorAnimation {
  0% {opacity: 0.2; left: -3em}
  100% {opacity: 1; left: 0.5em}
}

#toastWrapper{
  /* border: 2px solid crimson; */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.toast{
  /* border: 2px solid cadetblue; */
  padding: 5px 10px;
  border-radius: 3px;
  -webkit-animation: slideUp 1s 0s;
          animation: slideUp 1s 0s;
  color: white !important;
  text-align: center;
  margin: 2px 0px;
  position: absolute;
  bottom: 50px;
}
.toast:first-letter {text-transform: uppercase}
.success-toast{
  background-color: rgba(50, 50, 50, 0.8);
}

.error-toast{
  background-color: rgba(50, 50, 50, 0.8);
}

.collaboration-toast{
  background-color: rgba(50, 50, 50, 0.8);
}

.default-toast{
  background-color: rgba(50, 50, 50, 0.8);
}
@-webkit-keyframes slideUp {
  0%{
    bottom: -10px;
    opacity: 0;
  }
  100%{
    bottom: 50px;
    opacity: 1;
  }
}
@keyframes slideUp {
  0%{
    bottom: -10px;
    opacity: 0;
  }
  100%{
    bottom: 50px;
    opacity: 1;
  }
}

#pointers {
  position: relative;
}
#pointers .pointer {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url("https://uploads.codesandbox.io/uploads/user/88acfe5a-77fc-498c-98ee-d1b0b303f6a8/tC4n-pointer.png");
  z-index: 100;
}
#pointers .pointer-anchor {
  position: absolute;
  height: auto;
  z-index: 100;
  color: white;
  border: 2px solid white;
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
}
#hubspot-conversations-inline-iframe {
  width: 350px;
  height: 500px;
  border:none;
}
#canvas-zone{
  position: relative;
}
.comment-pin{
  position: absolute;
  z-index: 2;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: white;
  color: #BDBBBB;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.comment-pin span{
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.comment-pin.active{
  background-color: #EB1E45;
  color: white;
  z-index: 3;
}

.comment-pin:hover{
  background-color: #EB1E45;
  color: white;
  z-index: 3;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#scene-explorer-host, #inspector-host{
  z-index: 10;
}

model-viewer {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}


.progress-bar {
  display: block;
  width: 33%;
  height: 10%;
  max-height: 2%;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  border-radius: 25px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 1px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-bar.hide {
  visibility: hidden;
  transition: visibility 0.3s;
}

.update-bar {
  background-color: rgba(255, 255, 255, 0.9);
  width: 0%;
  height: 100%;
  border-radius: 25px;
  float: left;
  transition: width 0.3s;
}

@-webkit-keyframes circle {
  from { -webkit-transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
  to   { -webkit-transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
}

@keyframes circle {
  from { -webkit-transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
  to   { -webkit-transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
}

@-webkit-keyframes elongate {
  from { -webkit-transform: translateX(100px); transform: translateX(100px); }
  to   { -webkit-transform: translateX(-100px); transform: translateX(-100px); }
}

@keyframes elongate {
  from { -webkit-transform: translateX(100px); transform: translateX(100px); }
  to   { -webkit-transform: translateX(-100px); transform: translateX(-100px); }
}
.telInputContainer{
  width: 100%;
  font-size: 0.875rem; /* fontSize.tiny */

}
.telInput{
  border-radius: 0.3125rem;
  background: #F2F2F2;/* colors.white */
  color: #2D2D2E; /* colors.primeBlack */
  outline: 0;
  border: none;
  /* padding-left: 0.75rem;
  padding-right: 0.75rem; */
  /* letter-spacing: -0.021rem; */
  flex: 1 1;
  height: 2.5rem;
  width: 100%;
}
.selected-flag{
  min-width: none;
  padding-left: 5px;
  font-size: 0.875rem; /* fontSize.tiny */
}
.selected-flag > .iti-flag{
  position: absolute;
  top: 35%;
}
.arrow.down, .arrow.up{
  visibility: hidden;
}
.flag-container > ul{
  width: 100%;
  overflow-x: hidden;
}
/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

.hoverRed:hover {
  background-color: #ec1c45 !important;
  color: white !important;
}

/**
 * Value wrapper
 */
.select-search__value {
  position: relative;
  z-index: 1;
  background: rgba(242, 242, 242, 0.5);
  border-radius: 5px;
}

.actionsRow {
  margin-top: 2rem;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
 * Input
 */
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 2px;
  background: rgba(242, 242, 242, 0.5);
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.333333px;

  border: 1px solid transparent;
  border-radius: 5px;
  outline: none;
  text-align: left;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
 * Options wrapper
 */
.select-search__select {
  background: #ffffff;
  border: 1px solid rgba(87, 87, 88, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  color: #575758;
}

/**
 * Options
 */
.select-search__options {
  list-style: none;
  padding-left: 5px;
  padding-top: 2px;
  padding-bottom: 2px;
}

/**
 * Option row
 */
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
 * Option
 */
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 2px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #fff;
  color: #2d2d2e;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #f2f2f2;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: rgba(242, 242, 242, 0.5);
  border-radius: 5px;
}

/**
 * Group
 */
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
 * States
 */
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
 * Modifiers
 */
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: none;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 0px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}


