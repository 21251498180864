@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
@import url("https://api.mapbox.com/mapbox-gl-js/plugins/mapbox-gl-geocoder/v4.7.0/mapbox-gl-geocoder.css");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: visible;
}

code {
  font-family: "DM Sans", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

html,
body,
#root {
  font-size: 16px;
  height: 100%;
}
::-webkit-scrollbar-track {
  border-radius: 0.625em;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 0.1875em;
  height: 0.3em;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 3.125em;
  background-color: #818181;
}
/* ::-webkit-scrollbar {
  width: 5px;
  background-color: white;
}

::-webkit-scrollbar-track {
  box-shadow: #818181;
  -webkit-box-shadow: #818181; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */

.spinner {
    width: 80px;
    height: 80px;
    background-color: #eb1e45;
    -webkit-animation: square-1 1s infinite ease-in-out;
    animation: square-1 1s infinite ease-in-out;
    /* margin-right: auto;
    margin-left: auto;
    margin-top: 200px; */
  }


.detection-loader {
    border: 3px solid transparent;
    border-radius: 50%;
    border-top: 3px solid #EC1C45;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 0.7s linear infinite; /* Safari */
    animation: spin 0.7s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

  @keyframes square-1 {

    0% {
        transform: perspective(160px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(160px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
        -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(0deg);
    }
    100% {
        transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
        -webkit-transform: perspective(160px) rotateX(-180deg) rotateY(-180deg);
    }
}

@keyframes instructorAnimation {
  0% {opacity: 0.2; left: -3em}
  100% {opacity: 1; left: 0.5em}
}

#toastWrapper{
  /* border: 2px solid crimson; */
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 200;
}
.toast{
  /* border: 2px solid cadetblue; */
  padding: 5px 10px;
  border-radius: 3px;
  animation: slideUp 1s 0s;
  color: white !important;
  text-align: center;
  margin: 2px 0px;
  position: absolute;
  bottom: 50px;
}
.toast:first-letter {text-transform: uppercase}
.success-toast{
  background-color: rgba(50, 50, 50, 0.8);
}

.error-toast{
  background-color: rgba(50, 50, 50, 0.8);
}

.collaboration-toast{
  background-color: rgba(50, 50, 50, 0.8);
}

.default-toast{
  background-color: rgba(50, 50, 50, 0.8);
}
@keyframes slideUp {
  0%{
    bottom: -10px;
    opacity: 0;
  }
  100%{
    bottom: 50px;
    opacity: 1;
  }
}

#pointers {
  position: relative;
}
#pointers .pointer {
  position: absolute;
  width: 22px;
  height: 22px;
  background: url("https://uploads.codesandbox.io/uploads/user/88acfe5a-77fc-498c-98ee-d1b0b303f6a8/tC4n-pointer.png");
  z-index: 100;
}
#pointers .pointer-anchor {
  position: absolute;
  height: auto;
  z-index: 100;
  color: white;
  border: 2px solid white;
  border-radius: 6px;
  padding-left: 5px;
  padding-right: 5px;
}
#hubspot-conversations-inline-iframe {
  width: 350px;
  height: 500px;
  border:none;
}
#canvas-zone{
  position: relative;
}
.comment-pin{
  position: absolute;
  z-index: 2;
  width: 32px;
  height: 32px;
  cursor: pointer;
  background-color: white;
  color: #BDBBBB;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  border-bottom-right-radius: 0px;
  font-size: 14px;
  transform: rotate(45deg);
}
.comment-pin span{
  transform: rotate(-45deg);
}

.comment-pin.active{
  background-color: #EB1E45;
  color: white;
  z-index: 3;
}

.comment-pin:hover{
  background-color: #EB1E45;
  color: white;
  z-index: 3;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

#scene-explorer-host, #inspector-host{
  z-index: 10;
}

model-viewer {
  background-color: #ffffff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}


.progress-bar {
  display: block;
  width: 33%;
  height: 10%;
  max-height: 2%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  border-radius: 25px;
  box-shadow: 0px 3px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 1px rgba(0, 0, 0, 0.6);
  border: 1px solid rgba(255, 255, 255, 0.9);
  background-color: rgba(0, 0, 0, 0.5);
}

.progress-bar.hide {
  visibility: hidden;
  transition: visibility 0.3s;
}

.update-bar {
  background-color: rgba(255, 255, 255, 0.9);
  width: 0%;
  height: 100%;
  border-radius: 25px;
  float: left;
  transition: width 0.3s;
}

@keyframes circle {
  from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
  to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
}

@keyframes elongate {
  from { transform: translateX(100px); }
  to   { transform: translateX(-100px); }
}