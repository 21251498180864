.telInputContainer{
  width: 100%;
  font-size: 0.875rem; /* fontSize.tiny */

}
.telInput{
  border-radius: 0.3125rem;
  background: #F2F2F2;/* colors.white */
  color: #2D2D2E; /* colors.primeBlack */
  outline: 0;
  border: none;
  /* padding-left: 0.75rem;
  padding-right: 0.75rem; */
  /* letter-spacing: -0.021rem; */
  flex: 1;
  height: 2.5rem;
  width: 100%;
}
.selected-flag{
  min-width: none;
  padding-left: 5px;
  font-size: 0.875rem; /* fontSize.tiny */
}
.selected-flag > .iti-flag{
  position: absolute;
  top: 35%;
}
.arrow.down, .arrow.up{
  visibility: hidden;
}
.flag-container > ul{
  width: 100%;
  overflow-x: hidden;
}